import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  List,
  SearchInput,
  ShowButton,
  SimpleList,
  TextField,
  TopToolbar,
  usePermissions,
} from "react-admin";
import { useMediaQuery } from "@mui/material";

const CustomerFilter = [<SearchInput source="first_name" placeholder="Customer name" alwaysOn />];

const ListActions = () => {
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      <CreateButton />
      {permissions?.role === "Staff" && <ExportButton />}
    </TopToolbar>
  );
};

const UserList = () => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"), { noSsr: true });
  return (
    <List filters={CustomerFilter} actions={<ListActions />}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => `${record.first_name} ${record.last_name}`}
          secondaryText={(record) => record.username}
          tertiaryText={(record) => new Date(record.date_of_birth).toLocaleDateString()}
          linkType="show"
        />
      ) : (
        <Datagrid>
          <TextField source="id" label="Customer ID" emptyText="-" />
          <TextField source="first_name" emptyText="-" />
          <TextField source="last_name" emptyText="-" />
          <TextField source="gender" emptyText="-" />
          <DateField source="date_of_birth" emptyText="-" />
          <TextField label="Email" source="username" emptyText="-" />
          <TextField source="contact_number" emptyText="-" />
          <TextField source="account_type" emptyText="-" />
          <BooleanField source="set_verified" label="Verified" emptyText="-" />
          <BooleanField source="active" emptyText="-" />
          <ShowButton />
        </Datagrid>
      )}
    </List>
  );
};

export default UserList;
