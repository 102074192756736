import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Title } from "react-admin";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getRequest, getRequestWithHeaders } from "../../helpers/requests";

dayjs.extend(customParseFormat);

const BusinessPartnerTests = () => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [businessPartner, setBusinessPartner] = useState(null);
  const [businessPartnerOptions, setBusinessPartnerOptions] = useState([]);
  const [businessPartnerLoading, setBusinessPartnerLoading] = useState(false);
  const [testType, setTestType] = useState(null);
  const [order, setOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("");
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();

  const fetchTests = async () => {
    try {
      setLoading(true);
      const endpoint = "products/business-partner";

      const queryParams = {
        limit: rowsPerPage,
        page: page + 1,
      };

      if (sortBy) {
        queryParams.sort = sortBy;
        queryParams.order = order;
      }

      if (Object.keys(filters).length) {
        if (filters.businessPartner) {
          queryParams.business_partner = filters.businessPartner.business_partner_name;
        }
      }

      const response = await getRequestWithHeaders(endpoint, queryParams);

      if (response.headers) {
        const contentRange = response.headers.get("Content-Range");
        if (contentRange) {
          const [range, total] = contentRange.split("/");
          setTotalRows(parseInt(total, 10));
        } else {
          console.error("Content-Range header is missing");
        }
      }

      setTests(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchBusinessPartnerOptions = async () => {
    try {
      setBusinessPartnerLoading(true);
      const response = await getRequest("products/business-partner");
      const uniquePartners = Array.from(
        new Set(
          response.data.map((p) =>
            JSON.stringify({
              business_partner_name: p.business_partner_name,
              business_partner_user_id: p.business_partner_user_id,
            }),
          ),
        ),
      ).map((p) => JSON.parse(p));

      setBusinessPartnerOptions(uniquePartners);
      setBusinessPartnerLoading(false);
    } catch (error) {
      console.error("Error fetching business partners:", error);
      setBusinessPartnerLoading(false);
    }
  };

  useEffect(() => {
    fetchTests();
  }, [page, rowsPerPage, order, sortBy, filters]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGoToTest = (id) => {
    navigate(`/business-partner/tests/edit/${id}`);
  };

  const handleCreateNewTest = () => {
    navigate("/business-partner/tests/create");
  };

  const handleSearch = () => {
    setFilters({
      businessPartner,
      testType,
    });
    setPage(0);
  };

  const handleResetFilters = () => {
    setBusinessPartner(null);
    setTestType(null);
    setFilters({});
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const createSortHandler = (property) => () => {
    handleRequestSort(property);
  };

  const handleBusinessPartnerOpen = () => {
    if (!businessPartnerOptions.length && !businessPartnerLoading) fetchBusinessPartnerOptions();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Title title="List Business Partner Tests" />
      <Container maxWidth="false" sx={{ marginTop: "18px" }}>
        <Box sx={{ marginBottom: 2, display: "flex", gap: 2, justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Autocomplete
              options={businessPartnerOptions}
              getOptionLabel={(option) => option.business_partner_name}
              loading={businessPartnerLoading}
              onOpen={handleBusinessPartnerOpen}
              onChange={(event, newValue) =>
                setBusinessPartner(newValue ? { business_partner_name: newValue.business_partner_name } : null)
              }
              sx={{ width: 200 }}
              value={businessPartner}
              PaperComponent={({ children }) => <Paper style={{ width: 350 }}>{children}</Paper>}
              renderInput={(params) => <TextField {...params} label="Business Partner" />}
              getOptionSelected={(option, value) => option.business_partner_name === value.business_partner_name}
              renderOption={(props, option) => (
                <li {...props} key={option.business_partner_user_id}>
                  {option.business_partner_name}{" "}
                  <span style={{ color: "#aaa", fontSize: "12px", paddingLeft: "6px" }}>
                    (ID {option.business_partner_user_id})
                  </span>
                </li>
              )}
            />
            <Button variant="contained" onClick={handleSearch} sx={{ margin: "8px 0 4px" }}>
              Apply
            </Button>
            <Button variant="text" onClick={handleResetFilters} sx={{ margin: "8px 0 4px" }}>
              Reset
            </Button>
          </Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateNewTest}
            sx={{ margin: "8px 0 4px" }}
          >
            Create New
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection={sortBy === "test_id" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "test_id"}
                    direction={sortBy === "test_id" ? order : "asc"}
                    onClick={createSortHandler("test_id")}
                  >
                    Test ID
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "test_name" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "test_name"}
                    direction={sortBy === "test_name" ? order : "asc"}
                    onClick={createSortHandler("test_name")}
                  >
                    Test Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "business_partner_name" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "business_partner_name"}
                    direction={sortBy === "business_partner_name" ? order : "asc"}
                    onClick={createSortHandler("business_partner_name")}
                  >
                    Business Partner
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "test_price" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "test_price"}
                    direction={sortBy === "test_price" ? order : "asc"}
                    onClick={createSortHandler("test_price")}
                  >
                    Price
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "status" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "status"}
                    direction={sortBy === "status" ? order : "asc"}
                    onClick={createSortHandler("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sortBy === "enable_discount" ? order : false}>
                  <TableSortLabel
                    active={sortBy === "enable_discount"}
                    direction={sortBy === "enable_discount" ? order : "asc"}
                    onClick={createSortHandler("enable_discount")}
                  >
                    Discount
                  </TableSortLabel>
                </TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={8} align="left">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : (
                tests.map((test) => (
                  <TableRow key={test.test_id}>
                    <TableCell>{test.test_id || "-"}</TableCell>
                    <TableCell>{test.test_name || "-"}</TableCell>
                    <TableCell>{test.business_partner_name || "-"}</TableCell>
                    <TableCell>£{test.test_price ? (test.test_price / 100).toFixed(2) : "-"}</TableCell>
                    <TableCell>{test.status !== null ? (test.status ? "Active" : "Inactive") : "-"}</TableCell>
                    <TableCell>
                      {test.enable_discount !== null ? (test.enable_discount ? "Enabled" : "Disabled") : "-"}
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleGoToTest(test.test_id)}>
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
    </LocalizationProvider>
  );
};

export default BusinessPartnerTests;
