import { useState } from "react";
import { notifyError, notifySuccess } from "../../utils/toast";
import { postRequest } from "../../helpers/requests";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import { Button, CardBody, Label, Input, Select, Textarea } from "@windmill/react-ui";
import { useNavigate } from "react-router-dom";
import Loading from "components/preloader/Loading";

function ClinicianCreate() {
  const navigate = useNavigate();
  const [nurseProfile, setNurseProfile] = useState(null);
  const [openCardIndex, setOpenCardIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const locumShiftOptions = ["2-hr shift", "4-hr shift", "8-hr shift"];
  const nurseBandOptions = [5, 6, 7, 8];
  const travelDistances = [
    { label: "40", value: 40 },
    { label: "50", value: 50 },
    { label: "60", value: 60 },
    { label: "70", value: 70 },
    { label: "80", value: 80 },
    { label: "90", value: 90 },
    { label: "100", value: 100 },
    { label: ">100", value: 101 },
  ];

  const additionalSkillsOptions = [
    "ECG",
    "Vaccine",
    "Centrifuge",
    "Blood Pressure (Machine) for Home Visits",
    "Occupational Health",
    "Drug and Alcohol Screening (Urine)",
  ];

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    setNurseProfile((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAdditionalSkillsChange = (e) => {
    if (!nurseProfile) return;

    const skill = e.target.value;
    let currentSkills = nurseProfile.additional_skills ? nurseProfile.additional_skills.split(",") : [];
    currentSkills = currentSkills.filter((s) => s.trim() !== "");

    if (e.target.checked) {
      if (!currentSkills.includes(skill)) {
        currentSkills.push(skill);
      }
    } else {
      currentSkills = currentSkills.filter((s) => s !== skill);
    }

    const updatedSkills = currentSkills.join(",");
    setNurseProfile((prev) => ({
      ...prev,
      additional_skills: updatedSkills,
    }));
  };

  const handleLocumEventChange = (e) => {
    if (!nurseProfile) return;

    const eventOption = e.target.value;
    let currentEvents = nurseProfile.locum_group_events ? nurseProfile.locum_group_events.split(",") : [];
    currentEvents = currentEvents.filter((evt) => evt.trim() !== "");

    if (e.target.checked) {
      if (!currentEvents.includes(eventOption)) {
        currentEvents.push(eventOption);
      }
    } else {
      currentEvents = currentEvents.filter((evt) => evt !== eventOption);
    }

    const updatedEvents = currentEvents.join(",");
    setNurseProfile((prev) => ({
      ...prev,
      locum_group_events: updatedEvents,
    }));
  };

  const hasLocumEvent = (option) => {
    if (!nurseProfile?.locum_group_events) return false;
    return nurseProfile.locum_group_events.split(",").includes(option);
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "full_name",
      "phone_number",
      "personal_emails",
      "address_line1",
      "city",
      "postcode",
      "country",
      "password",
      "confirm_password",
      "first_person_rate",
      "additional_person_rate",
      "hourly_rate",
    ];

    requiredFields.forEach((field) => {
      if (!nurseProfile[field] || nurseProfile[field].trim() === "") {
        newErrors[field] = "This field is required";
      }
    });

    if (nurseProfile.password && nurseProfile.confirm_password) {
      if (nurseProfile.password !== nurseProfile.confirm_password) {
        newErrors.confirm_password = "Passwords do not match";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!nurseProfile) {
      notifyError("Cannot save profile. The fields are empty");
      return;
    }
    if (!validateForm()) {
      notifyError("Please fill all required fields correctly.");
      return;
    }
    try {
      setLoading(true);
      const response = await postRequest(`users/create-clinician-user`, nurseProfile);
      if (!response || response.status === "error") {
        notifyError("An error occurred while creating your profile");
        return;
      } else {
        notifySuccess("Profile created successfully!");
        setNurseProfile(null);
      }
    } catch (err) {
      console.error(err);
      notifyError("An error occurred while creating your profile");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const hasSkill = (skill) => {
    if (!nurseProfile?.additional_skills) return false;
    return nurseProfile.additional_skills.split(",").includes(skill);
  };

  if (loading) return <Loading loading={loading} />;

  return (
    <>
      <button
        onClick={handleBack}
        className="inline-flex items-center text-black hover:text-gray-700 
             border border-black/0 hover:border-black px-3 py-1 
             rounded-lg mt-3 mb-2 transition-all w-auto"
      >
        ← Back
      </button>
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white mt-5 mb-5">
        <CardHeader title="Create a clinician" subheader="Create an account and add nurse profile information here." />
        <Collapse in={openCardIndex === 0} timeout="auto" unmountOnExit>
          <CardBody>
            <form onSubmit={handleSave}>
              <div className="grid grid-cols-4 gap-4">
                <h2 className="col-span-4 text-lg font-semibold">Clinician Information</h2>

                <div className="col-span-4 grid grid-cols-3 gap-4">
                  <div>
                    <Label>
                      <span>Full Name *</span>
                      <Input
                        name="full_name"
                        type="text"
                        value={nurseProfile?.full_name || ""}
                        onChange={handleChange}
                      />
                      {errors.full_name && <p className="text-red-500 text-sm">{errors.full_name}</p>}
                    </Label>
                  </div>
                  <div>
                    <Label>
                      <span>Phone Number *</span>
                      <Input
                        name="phone_number"
                        type="text"
                        value={nurseProfile?.phone_number || ""}
                        onChange={handleChange}
                      />
                      {errors.phone_number && <p className="text-red-500 text-sm">{errors.phone_number}</p>}
                    </Label>
                  </div>

                  <div>
                    <Label>
                      <span>Nurse Email *</span>
                      <Input
                        name="personal_emails"
                        type="email"
                        value={nurseProfile?.personal_emails || ""}
                        onChange={handleChange}
                      />
                      {errors.personal_emails && <p className="text-red-500 text-sm">{errors.personal_emails}</p>}
                    </Label>
                  </div>

                  <div className="col-span-2">
                    <Label>
                      <span>Address Line 1 *</span>
                      <Input
                        name="address_line1"
                        type="text"
                        value={nurseProfile?.address_line1 || ""}
                        onChange={handleChange}
                      />
                      {errors.address_line1 && <p className="text-red-500 text-sm">{errors.address_line1}</p>}
                    </Label>
                  </div>

                  <div className="col-span-1">
                    <Label>
                      <span>Address Line 2</span>
                      <Input
                        name="address_line2"
                        type="text"
                        value={nurseProfile?.address_line2 || ""}
                        onChange={handleChange}
                      />
                    </Label>
                  </div>

                  <div>
                    <Label>
                      <span>City *</span>
                      <Input name="city" type="text" value={nurseProfile?.city || ""} onChange={handleChange} />
                    </Label>
                  </div>

                  <div>
                    <Label>
                      <span>Postcode *</span>
                      <Input name="postcode" type="text" value={nurseProfile?.postcode || ""} onChange={handleChange} />
                    </Label>
                  </div>

                  <div>
                    <Label>
                      <span>Country *</span>
                      <Input name="country" type="text" value={nurseProfile?.country || ""} onChange={handleChange} />
                    </Label>
                  </div>
                </div>

                <div className="col-span-2">
                  <Label>
                    <span>Account password *</span>
                    <Input name="password" type="text" value={nurseProfile?.password || ""} onChange={handleChange} />
                    {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
                  </Label>
                </div>
                <div className="col-span-2">
                  <Label>
                    <span>Confirm password *</span>
                    <Input
                      name="confirm_password"
                      type="text"
                      value={nurseProfile?.confirm_password || ""}
                      onChange={handleChange}
                    />
                    {errors.confirm_password && <p className="text-red-500 text-sm">{errors.confirm_password}</p>}
                  </Label>
                </div>

                <h2 className="col-span-full text-lg font-semibold">Nurse Professional Details</h2>

                <div className="col-span-1">
                  <Label>
                    <span>Insurance Expiry Date</span>
                    <Input
                      name="insurance_expiry_date"
                      type="date"
                      value={nurseProfile?.insurance_expiry_date || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Cover Amount per Claim</span>
                    <Input
                      name="insurance_cover_amount_per_claim"
                      type="number"
                      step="0.01"
                      value={nurseProfile?.insurance_cover_amount_per_claim || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Travel Distance for Series Jobs</span>
                    <Select
                      name="travel_distance_for_series_jobs"
                      value={nurseProfile?.travel_distance_for_series_jobs ?? ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Distance</option>
                      {travelDistances.map((dist) => (
                        <option key={dist.value} value={dist.value}>
                          {dist.label}
                        </option>
                      ))}
                    </Select>
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Youngest Age Phlebotomy</span>
                    <Input
                      name="youngest_age_phlebotomy"
                      type="number"
                      value={nurseProfile?.youngest_age_phlebotomy || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Years of Experience in Phlebotomy</span>
                    <Input
                      name="years_experience_phlebotomy"
                      type="number"
                      value={nurseProfile?.years_experience_phlebotomy || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Nurse Band Number</span>
                    <Select
                      name="nurse_band_number"
                      value={nurseProfile?.nurse_band_number || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select band</option>
                      {nurseBandOptions.map((band) => (
                        <option key={band} value={band}>
                          {band}
                        </option>
                      ))}
                    </Select>
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Allowed to? *</span>
                    <div className="flex items-center gap-4 mt-1">
                      <Label check key="event" className="inline-flex items-center gap-2">
                        <Input type="checkbox" name="allowed_to_event" onChange={handleChange} />
                        <span>Event</span>
                      </Label>
                      <Label check key="locum" className="inline-flex items-center gap-2">
                        <Input type="checkbox" name="allowed_to_locum" onChange={handleChange} />
                        <span>Locum</span>
                      </Label>
                    </div>
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Locum/Group Events</span>
                    <div className="flex items-center gap-4 mt-1">
                      {locumShiftOptions.map((shift) => (
                        <Label check key={shift} className="inline-flex items-center gap-2">
                          <Input
                            type="checkbox"
                            name="locum_group_events"
                            value={shift}
                            checked={hasLocumEvent(shift)}
                            onChange={handleLocumEventChange}
                          />
                          <span>{shift}</span>
                        </Label>
                      ))}
                    </div>
                  </Label>
                </div>

                <div className="col-span-full flex flex-wrap items-center space-x-4">
                  <Label check>
                    <Input
                      type="checkbox"
                      name="had_insurance_claim_in_last_5_years"
                      checked={!!nurseProfile?.had_insurance_claim_in_last_5_years}
                      onChange={handleChange}
                    />
                    <span className="ml-2">Does the nurse have an insurance claim in last 5 Years?</span>
                  </Label>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="have_vehicle"
                      checked={!!nurseProfile?.have_vehicle}
                      onChange={handleChange}
                    />
                    <span className="ml-2">Does the nurse have a vehicle?</span>
                  </Label>
                </div>

                <h2 className="col-span-full text-lg font-semibold">Additional Skills</h2>
                <p className="col-span-full text-sm text-gray-600">Please tick all additional skills the nurse has:</p>

                <div className="col-span-full grid grid-cols-1 gap-2">
                  {additionalSkillsOptions.map((skill) => {
                    const isChecked = hasSkill(skill);
                    return (
                      <Label check key={skill}>
                        <Input
                          type="checkbox"
                          name="additional_skills"
                          value={skill}
                          checked={isChecked}
                          onChange={handleAdditionalSkillsChange}
                        />
                        <span className="ml-2">{skill}</span>
                      </Label>
                    );
                  })}
                </div>

                {/* FINANCIAL DETAILS */}
                <h2 className="col-span-full text-lg font-semibold">Financial Details</h2>

                <div className="col-span-1">
                  <Label>
                    <span>Bank Account Name</span>
                    <Input
                      name="bank_account_name"
                      type="text"
                      value={nurseProfile?.bank_account_name || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Bank Account Sort Code</span>
                    <Input
                      name="bank_account_sort_code"
                      type="text"
                      value={nurseProfile?.bank_account_sort_code || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Bank Account Number</span>
                    <Input
                      name="bank_account_number"
                      type="text"
                      value={nurseProfile?.bank_account_number || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Contract Date</span>
                    <Input
                      name="sirkka_onboarding_date"
                      type="date"
                      value={nurseProfile?.sirkka_onboarding_date || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>First Person Rate *</span>
                    <Input
                      name="first_person_rate"
                      type="number"
                      step="0.01"
                      value={nurseProfile?.first_person_rate || ""}
                      onChange={handleChange}
                    />
                    {errors.first_person_rate && <p className="text-red-500 text-sm">{errors.first_person_rate}</p>}
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Additional Person Rate *</span>
                    <Input
                      name="additional_person_rate"
                      type="number"
                      step="0.01"
                      value={nurseProfile?.additional_person_rate || ""}
                      onChange={handleChange}
                    />
                    {errors.additional_person_rate && (
                      <p className="text-red-500 text-sm">{errors.additional_person_rate}</p>
                    )}
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Hourly Rate *</span>
                    <Input
                      name="hourly_rate"
                      type="number"
                      step="0.01"
                      value={nurseProfile?.hourly_rate || ""}
                      onChange={handleChange}
                    />
                    {errors.hourly_rate && <p className="text-red-500 text-sm">{errors.hourly_rate}</p>}
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Sunday Extra Rate (%) *</span>
                    <Input
                      name="sunday_extra_rate_percent"
                      type="number"
                      step="0.01"
                      value={nurseProfile?.sunday_extra_rate_percent || ""}
                      onChange={handleChange}
                    />
                    {/* {errors.sunday_extra_rate_percent && (
                      <p className="text-red-500 text-sm">{errors.sunday_extra_rate_percent}</p>
                    )} */}
                  </Label>
                </div>
                <div className="col-span-4">
                  <Label>
                    <span>Additional Information (Training, content, etc.)</span>
                    <Textarea
                      rows="4"
                      name="latest_training_date_content"
                      placeholder="Enter any additional information here."
                      value={nurseProfile?.latest_training_date_content || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>
              </div>

              <div className="flex justify-center mt-5">
                <Button type="submit" className="!bg-blue-500 !hover:bg-blue-600 !text-white" disabled={loading}>
                  {loading ? "Creating..." : "Create Profile"}
                </Button>
              </div>
            </form>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

export default ClinicianCreate;
