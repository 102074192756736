import { useState, useEffect } from "react";
import { notifyError, notifySuccess } from "../../utils/toast";
import { getRequest, patchRequest, deleteRequest } from "../../helpers/requests";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import { Button, CardBody, Label, Input, Select } from "@windmill/react-ui";
import { useNavigate, useParams } from "react-router-dom";
import { usePermissions } from "react-admin";
import Loading from "components/preloader/Loading";

function NurseProfile() {
  const { userId: urlUserId } = useParams();
  const { permissions, isLoading } = usePermissions();
  const navigate = useNavigate();
  const [nurseProfile, setNurseProfile] = useState(null);
  const [openCardIndex, setOpenCardIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  const locumShiftOptions = ["2-hr shift", "4-hr shift", "8-hr shift"];
  const nurseBandOptions = [5, 6, 7, 8];
  const travelDistances = [
    { label: "40", value: 40 },
    { label: "50", value: 50 },
    { label: "60", value: 60 },
    { label: "70", value: 70 },
    { label: "80", value: 80 },
    { label: "90", value: 90 },
    { label: "100", value: 100 },
    { label: ">100", value: 101 },
  ];

  const additionalSkillsOptions = [
    "ECG",
    "Vaccine",
    "Centrifuge",
    "Blood Pressure (Machine) for Home Visits",
    "Occupational Health",
    "Drug and Alcohol Screening (Urine)",
  ];

  useEffect(() => {
    if (isLoading) return <Loading loading={isLoading} />;

    if (urlUserId) {
      // If admin clicks on a nurse profile, use user_id from URL
      setUserId(urlUserId);
    } else if (permissions?.role === "Clinician") {
      const storedMeta = localStorage.getItem("user_metadata");
      if (storedMeta) {
        try {
          const parsed = JSON.parse(storedMeta);
          if (parsed?.user_id) {
            setUserId(parsed.user_id);
          }
        } catch (err) {
          console.error("Error parsing user_metadata from localStorage:", err);
        }
      }
    }
  }, [permissions, isLoading, urlUserId]);

  useEffect(() => {
    if (!userId) return;

    const fetchNurseProfile = async () => {
      try {
        const response = await getRequest(`nurse-profile/${userId}`);
        if (response?.data) {
          const profileData = response.data;
          // Parse the training notes field if it's a string.
          if (profileData.latest_training_date_content && typeof profileData.latest_training_date_content === "string") {
            try {
              profileData.latest_training_date_content = JSON.parse(profileData.latest_training_date_content);
            } catch (err) {
              profileData.latest_training_date_content = [];
            }
          }
          // Ensure the field is always an array.
          if (!profileData.latest_training_date_content) {
            profileData.latest_training_date_content = [];
          }
          setNurseProfile(profileData);
          localStorage.setItem("nurse_profile", JSON.stringify(profileData));
        } else {
          console.warn("No nurse profile data returned");
          setNurseProfile(null);
        }
      } catch (err) {
        console.error("Error fetching nurse profile:", err);
      }
    };
  
    fetchNurseProfile();
  }, [userId]);  

  const handleChange = (e) => {
    if (!nurseProfile) return;

    const { name, type, checked, value } = e.target;
    const restrictedFields = new Set([
      "hourly_rate",
      "sunday_extra_rate_percent",
      "first_person_rate",
      "additional_person_rate",
      "sirkka_onboarding_date",
    ]);
    if (permissions?.role === "Clinician" && restrictedFields.has(name)) return;

    setNurseProfile((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDelete = async () => {
    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this nurse?\nThis action cannot be undone."
    );
    if (!confirmDeletion) return;
  
    try {
      setLoading(true);
      
      const apiKey = process.env.REACT_APP_DELETION_API_KEY;
      if (!apiKey) {
        console.error("Missing deletion API key in environment variables.");
        notifyError("System error: Missing API key.");
        return;
      }
  
      await deleteRequest(`nurse-profile/${userId}`, {}, { "x-api-key": apiKey });
  
      notifySuccess("Nurse profile deleted successfully!");
      navigate("/availability/clinician");
    } catch (err) {
      console.error("Error deleting nurse:", err);
      notifyError("An error occurred while deleting the nurse profile.");
    } finally {
      setLoading(false);
    }
  };  

  const addTrainingNote = () => {
    const newNote = { date: "", note: "" };
    setNurseProfile((prev) => ({
      ...prev,
      latest_training_date_content: [...(prev.latest_training_date_content || []), newNote],
    }));
  };
  
  const removeTrainingNote = (index) => {
    setNurseProfile((prev) => {
      const updatedNotes = prev.latest_training_date_content.filter((_, i) => i !== index);
      return { ...prev, latest_training_date_content: updatedNotes };
    });
  };
  
  const handleTrainingNoteChange = (index, field, value) => {
    setNurseProfile((prev) => {
      const updatedNotes = prev.latest_training_date_content.map((note, i) => {
        if (i === index) {
          return { ...note, [field]: value };
        }
        return note;
      });
      return { ...prev, latest_training_date_content: updatedNotes };
    });
  };
  
  const handleAdditionalSkillsChange = (e) => {
    if (!nurseProfile) return;

    const skill = e.target.value;
    let currentSkills = nurseProfile.additional_skills ? nurseProfile.additional_skills.split(",") : [];
    currentSkills = currentSkills.filter((s) => s.trim() !== "");

    if (e.target.checked) {
      if (!currentSkills.includes(skill)) {
        currentSkills.push(skill);
      }
    } else {
      currentSkills = currentSkills.filter((s) => s !== skill);
    }

    const updatedSkills = currentSkills.join(",");
    setNurseProfile((prev) => ({
      ...prev,
      additional_skills: updatedSkills,
    }));
  };

  const handleLocumEventChange = (e) => {
    if (!nurseProfile) return;

    const eventOption = e.target.value;
    let currentEvents = nurseProfile.locum_group_events ? nurseProfile.locum_group_events.split(",") : [];
    currentEvents = currentEvents.filter((evt) => evt.trim() !== "");

    if (e.target.checked) {
      if (!currentEvents.includes(eventOption)) {
        currentEvents.push(eventOption);
      }
    } else {
      currentEvents = currentEvents.filter((evt) => evt !== eventOption);
    }

    const updatedEvents = currentEvents.join(",");
    setNurseProfile((prev) => ({
      ...prev,
      locum_group_events: updatedEvents,
    }));
  };

  const hasLocumEvent = (option) => {
    if (!nurseProfile?.locum_group_events) return false;
    return nurseProfile.locum_group_events.split(",").includes(option);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!userId || !nurseProfile) {
      notifyError("Cannot save profile.");
      return;
    }

    try {
      notifySuccess("Updating your profile...");
      setLoading(true);
      await patchRequest(`nurse-profile/${userId}`, nurseProfile);
      notifySuccess("Profile updated successfully!");
      localStorage.setItem("nurse_profile", JSON.stringify(nurseProfile));
    } catch (err) {
      console.error(err);
      notifyError("An error occurred while updating your profile");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const hasSkill = (skill) => {
    if (!nurseProfile?.additional_skills) return false;
    return nurseProfile.additional_skills.split(",").includes(skill);
  };

  if (isLoading) return <Loading loading={isLoading} />;

  return (
    <>
      <button
        onClick={handleBack}
        className="inline-flex items-center text-black hover:text-gray-700 
             border border-black/0 hover:border-black px-3 py-1 
             rounded-lg mt-3 mb-2 transition-all w-auto"
      >
        ← Back
      </button>
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white mt-5 mb-5">
          <CardHeader
            title={
              <div className="flex items-center justify-between w-full">
                <span>
                  {permissions?.role === "Clinician"
                    ? "Your Profile"
                    : `${nurseProfile?.full_name || "Nurse"}'s Profile`}
                </span>

                {permissions?.role !== "Clinician" && (
                  <Button
                    onClick={handleDelete}
                    className="!bg-red-500 !hover:bg-red-600 !text-white"
                  >
                    Delete
                  </Button>
                )}
              </div>
            }
            subheader={
              permissions?.role === "Clinician"
                ? "View and edit your nurse profile information here."
                : "View and edit nurse profile information here."
            }
          />

          <Collapse in={openCardIndex === 0} timeout="auto" unmountOnExit>
          <CardBody>
            <form onSubmit={handleSave}>
              <div className="grid grid-cols-4 gap-4">
                {nurseProfile && permissions?.role !== "Clinician" && (
                  <>
                    <h2 className="col-span-full text-lg font-semibold mt-5">
                      Nurse Internal Rating
                    </h2>
                    <div className="col-span-1">
                      <Label>
                        <span>Internal Rating (0 to 5)</span>
                        <Input
                          name="internal_rating"
                          type="number"
                          min={0}
                          max={5}
                          step={1}
                          value={nurseProfile?.internal_rating ?? ""}
                          onChange={handleChange}
                        />
                      </Label>
                    </div>
                  </>
                )}

                {/* PERSONAL INFORMATION */}
                <h2 className="col-span-4 text-lg font-semibold">Personal Information</h2>

                <div className="col-span-3 grid grid-cols-3 gap-4">
                  <div>
                    <Label>
                      <span>Full Name</span>
                      <Input
                        name="full_name"
                        type="text"
                        value={nurseProfile?.full_name || ""}
                        onChange={handleChange}
                      />
                    </Label>
                  </div>

                  <div>
                    <Label>
                      <span>Phone Number</span>
                      <Input
                        name="phone_number"
                        type="text"
                        value={nurseProfile?.phone_number || ""}
                        onChange={handleChange}
                      />
                    </Label>
                  </div>

                  <div>
                    <Label>
                      <span>Personal Emails</span>
                      <Input
                        name="personal_emails"
                        type="email"
                        value={nurseProfile?.personal_emails || ""}
                        onChange={handleChange}
                      />
                    </Label>
                  </div>

                  <div className="col-span-3">
                    <Label>
                      <span>Address Line 1</span>
                      <Input
                        name="address_line1"
                        type="text"
                        value={nurseProfile?.address_line1 || ""}
                        onChange={handleChange}
                      />
                    </Label>
                  </div>

                  <div className="col-span-3">
                    <Label>
                      <span>Address Line 2</span>
                      <Input
                        name="address_line2"
                        type="text"
                        value={nurseProfile?.address_line2 || ""}
                        onChange={handleChange}
                      />
                    </Label>
                  </div>

                  <div>
                    <Label>
                      <span>City</span>
                      <Input name="city" type="text" value={nurseProfile?.city || ""} onChange={handleChange} />
                    </Label>
                  </div>

                  <div>
                    <Label>
                      <span>Postcode</span>
                      <Input name="postcode" type="text" value={nurseProfile?.postcode || ""} onChange={handleChange} />
                    </Label>
                  </div>

                  <div>
                    <Label>
                      <span>Country</span>
                      <Input name="country" type="text" value={nurseProfile?.country || ""} onChange={handleChange} />
                    </Label>
                  </div>
                </div>

                {/* PROFESSIONAL DETAILS */}
                <h2 className="col-span-full text-lg font-semibold">Professional Details</h2>

                <div className="col-span-1">
                  <Label>
                    <span>Insurance Expiry Date</span>
                    <Input
                      name="insurance_expiry_date"
                      type="date"
                      value={nurseProfile?.insurance_expiry_date || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Cover Amount per Claim</span>
                    <Input
                      name="insurance_cover_amount_per_claim"
                      type="number"
                      step="0.01"
                      value={nurseProfile?.insurance_cover_amount_per_claim || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Travel Distance for Series Jobs</span>
                    <Select
                      name="travel_distance_for_series_jobs"
                      value={nurseProfile?.travel_distance_for_series_jobs ?? ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Distance</option>
                      {travelDistances.map((dist) => (
                        <option key={dist.value} value={dist.value}>
                          {dist.label}
                        </option>
                      ))}
                    </Select>
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Youngest Age Phlebotomy</span>
                    <Input
                      name="youngest_age_phlebotomy"
                      type="number"
                      value={nurseProfile?.youngest_age_phlebotomy || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Years of Experience in Phlebotomy</span>
                    <Input
                      name="years_experience_phlebotomy"
                      type="number"
                      value={nurseProfile?.years_experience_phlebotomy || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Nurse Band Number</span>
                    <Select
                      name="nurse_band_number"
                      value={nurseProfile?.nurse_band_number || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select band</option>
                      {nurseBandOptions.map((band) => (
                        <option key={band} value={band}>
                          {band}
                        </option>
                      ))}
                    </Select>
                  </Label>
                </div>

                {(nurseProfile?.allowed_to_event || nurseProfile?.allowed_to_locum) && (
                  <div className="col-span-2">
                    <Label>
                      <span>Locum/Group Events</span>
                      <div className="flex items-center gap-4 mt-1">
                        {locumShiftOptions.map((shift) => (
                          <Label check key={shift} className="inline-flex items-center gap-2">
                            <Input
                              type="checkbox"
                              name="locum_group_events"
                              value={shift}
                              checked={hasLocumEvent(shift)}
                              onChange={handleLocumEventChange}
                            />
                            <span>{shift}</span>
                          </Label>
                        ))}
                      </div>
                    </Label>
                  </div>
                )}

                <div className="col-span-full flex flex-wrap items-center space-x-4">
                  <Label check>
                    <Input
                      type="checkbox"
                      name="had_insurance_claim_in_last_5_years"
                      checked={!!nurseProfile?.had_insurance_claim_in_last_5_years}
                      onChange={handleChange}
                    />
                    <span className="ml-2">Had Insurance Claim in Last 5 Years</span>
                  </Label>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="have_vehicle"
                      checked={!!nurseProfile?.have_vehicle}
                      onChange={handleChange}
                    />
                    <span className="ml-2">Have Vehicle</span>
                  </Label>
                </div>

                <h2 className="col-span-full text-lg font-semibold">Additional Skills</h2>
                <p className="col-span-full text-sm text-gray-600">Please tick all additional skills you have:</p>

                <div className="col-span-full grid grid-cols-1 gap-2">
                  {additionalSkillsOptions.map((skill) => {
                    const isChecked = hasSkill(skill);
                    return (
                      <Label check key={skill}>
                        <Input
                          type="checkbox"
                          name="additional_skills"
                          value={skill}
                          checked={isChecked}
                          onChange={handleAdditionalSkillsChange}
                        />
                        <span className="ml-2">{skill}</span>
                      </Label>
                    );
                  })}
                </div>

                {/* FINANCIAL DETAILS */}
                <h2 className="col-span-full text-lg font-semibold">Financial Details</h2>

                <div className="col-span-1">
                  <Label>
                    <span>Bank Account Name</span>
                    <Input
                      name="bank_account_name"
                      type="text"
                      value={nurseProfile?.bank_account_name || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Bank Account Sort Code</span>
                    <Input
                      name="bank_account_sort_code"
                      type="text"
                      value={nurseProfile?.bank_account_sort_code || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Bank Account Number</span>
                    <Input
                      name="bank_account_number"
                      type="text"
                      value={nurseProfile?.bank_account_number || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Onboarding Date</span>
                    <Input
                      name="sirkka_onboarding_date"
                      type="date"
                      value={nurseProfile?.sirkka_onboarding_date || ""}
                      disabled={permissions?.role === "Clinician"}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>First Person Rate</span>
                    <Input
                      name="first_person_rate"
                      type="number"
                      step="0.01"
                      value={nurseProfile?.first_person_rate || ""}
                      disabled={permissions?.role === "Clinician"}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Additional Person Rate</span>
                    <Input
                      name="additional_person_rate"
                      type="number"
                      step="0.01"
                      value={nurseProfile?.additional_person_rate || ""}
                      disabled={permissions?.role === "Clinician"}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Hourly Rate</span>
                    <Input
                      name="hourly_rate"
                      type="number"
                      step="0.01"
                      value={nurseProfile?.hourly_rate || ""}
                      disabled={permissions?.role === "Clinician"}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-1">
                  <Label>
                    <span>Sunday Extra Rate (%)</span>
                    <Input
                      name="sunday_extra_rate_percent"
                      type="number"
                      step="0.01"
                      value={nurseProfile?.sunday_extra_rate_percent || ""}
                      disabled={permissions?.role === "Clinician"}
                      onChange={handleChange}
                    />
                  </Label>
                </div>
                {nurseProfile && permissions?.role !== "Clinician" && (
                  <div className="col-span-full mt-5">
                    <h2 className="text-lg font-semibold">Training Notes</h2>
                    {nurseProfile.latest_training_date_content && nurseProfile.latest_training_date_content.length > 0 ? (
                      nurseProfile.latest_training_date_content.map((note, index) => (
                        <div key={index} className="grid grid-cols-3 gap-4 mt-2">
                          <div>
                            <Label>
                              <span>Date</span>
                              <Input
                                type="date"
                                value={note.date || ""}
                                onChange={(e) =>
                                  handleTrainingNoteChange(index, "date", e.target.value)
                                }
                              />
                            </Label>
                          </div>
                          <div className="col-span-1">
                            <Label>
                              <span>Note</span>
                              <Input
                                type="text"
                                value={note.note || ""}
                                onChange={(e) =>
                                  handleTrainingNoteChange(index, "note", e.target.value)
                                }
                              />
                            </Label>
                          </div>
                          <div className="flex items-end">
                            <Button
                              type="button"
                              onClick={() => removeTrainingNote(index)}
                              className="!bg-red-500 !hover:bg-red-600 !text-white"
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-gray-600">No training notes added.</p>
                    )}
                    <div className="flex justify-center mt-3">
                    <Button
                      type="button"
                      onClick={addTrainingNote}
                      className="mt-3 !bg-green-500 !hover:bg-green-600 !text-white"
                    >
                      Add Training Note
                    </Button>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-center mt-5">
                <Button type="submit" className="!bg-blue-500 !hover:bg-blue-600 !text-white" disabled={loading}>
                  {loading ? "Saving..." : "Save Profile"}
                </Button>
              </div>
            </form>
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
}

export default NurseProfile;
