import { useState, useEffect } from "react";
import { notifyError, notifySuccess } from "../../utils/toast";
import { postRequest, getRequest, patchRequest } from "../../helpers/requests";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Button, CardBody, Label, Input, Select, Textarea } from "@windmill/react-ui";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "components/preloader/Loading";

function BusinessPartnerTestForm({ mode = "create" }) {
  const navigate = useNavigate();
  const { testId } = useParams();
  const [testData, setTestData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [businessPartnerOptions, setBusinessPartnerOptions] = useState([]);
  const [businessPartnerLoading, setBusinessPartnerLoading] = useState(false);

  useEffect(() => {
    const fetchBusinessPartners = async () => {
      try {
        setBusinessPartnerLoading(true);
        const partners = ["The Independent Nurse", "CFMH", "EightHealthcare"];
        setBusinessPartnerOptions(partners);
      } catch (error) {
        notifyError("Failed to load business partners");
      } finally {
        setBusinessPartnerLoading(false);
      }
    };
    fetchBusinessPartners();
  }, []);

  useEffect(() => {
    if (mode === "edit" && testId) {
      const fetchTestData = async () => {
        try {
          setLoading(true);
          const response = await getRequest(`products/business-partner/tests/${testId}`);
          if (response && response.data) {
            setTestData(response.data);
          } else {
            notifyError("Failed to load test data");
          }
        } catch (error) {
          notifyError("Failed to load test data");
        } finally {
          setLoading(false);
        }
      };
      fetchTestData();
    }
  }, [mode, testId]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setTestData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["test_name", "business_partner_name", "test_price"];
    requiredFields.forEach((field) => {
      if (!testData[field] || testData[field].toString().trim() === "") {
        newErrors[field] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!testData) return notifyError("Cannot save test. The fields are empty");
    if (!validateForm()) return notifyError("Please fill all required fields correctly.");
    try {
      setLoading(true);
      let response;
      if (mode === "create") response = await postRequest(`products/business-partner/create-test`, testData);
      else if (mode === "edit") response = await patchRequest(`products/business-partner/tests/${testId}`, testData);
      if (!response || response.status === "error") return notifyError("An error occurred while saving the test");
      notifySuccess(mode === "create" ? "Test created successfully!" : "Test updated successfully!");
      navigate(-1);
    } catch (err) {
      notifyError("An error occurred while saving the test");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => navigate(-1);

  if (loading) return <Loading loading={loading} />;

  return (
    <>
      <button
        onClick={handleBack}
        className="inline-flex items-center text-black hover:text-gray-700 border border-black/0 hover:border-black px-3 py-1 rounded-lg mt-3 mb-2 transition-all w-auto"
      >
        ← Back
      </button>
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white mt-5 mb-5">
        <CardHeader
          title={mode === "create" ? "Create a Business Partner Test" : "Edit Business Partner Test"}
          subheader={
            mode === "create" ? "Add a new test for a business partner." : `Edit details for Test ID: ${testId}`
          }
        />
        <CardBody>
          <form onSubmit={handleSave}>
            <div className="grid grid-cols-4 gap-4">
              <h2 className="col-span-4 text-lg font-semibold">Test Information</h2>

              <div className="col-span-2">
                <Label>
                  <span>Test Name *</span>
                  <Input name="test_name" type="text" value={testData?.test_name || ""} onChange={handleChange} />
                  {errors.test_name && <p className="text-red-500 text-sm">{errors.test_name}</p>}
                </Label>
              </div>

              <div className="col-span-2">
                <Label>
                  <span>Business Partner *</span>
                  <Select
                    name="business_partner_name"
                    value={testData?.business_partner_name || ""}
                    onChange={handleChange}
                    disabled={businessPartnerLoading}
                  >
                    <option value="">Select Business Partner</option>
                    {businessPartnerOptions.map((partner) => (
                      <option key={partner} value={partner}>
                        {partner}
                      </option>
                    ))}
                  </Select>
                  {errors.business_partner_name && (
                    <p className="text-red-500 text-sm">{errors.business_partner_name}</p>
                  )}
                </Label>
              </div>

              <div className="col-span-2">
                <Label>
                  <span>TDL Lab Code</span>
                  <Input name="tdl_lab_code" type="text" value={testData?.tdl_lab_code || ""} onChange={handleChange} />
                </Label>
              </div>

              <div className="col-span-2">
                <Label>
                  <span>Sirkka Identifier</span>
                  <Input
                    name="sirkka_identifier"
                    type="text"
                    value={testData?.sirkka_test_identifier || ""}
                    onChange={handleChange}
                  />
                </Label>
              </div>

              <div className="col-span-2">
                <Label>
                  <span>Test Price (£) *</span>
                  <Input
                    name="test_price"
                    type="number"
                    step="0.01"
                    value={testData?.test_price || ""}
                    onChange={handleChange}
                  />
                  {errors.test_price && <p className="text-red-500 text-sm">{errors.test_price}</p>}
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Status *</span>
                  <div className="flex items-center gap-4 mt-1">
                    <Label check className="inline-flex items-center gap-2">
                      <Input type="checkbox" name="status" checked={!!testData?.status} onChange={handleChange} />
                      Active
                    </Label>
                  </div>
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Enable Discount</span>
                  <div className="flex items-center gap-4 mt-1">
                    <Label check className="inline-flex items-center gap-2">
                      <Input
                        type="checkbox"
                        name="enable_discount"
                        checked={!!testData?.enable_discount}
                        onChange={handleChange}
                      />
                      Enabled
                    </Label>
                  </div>
                </Label>
              </div>

              <div className="col-span-4">
                <Label>
                  <span>Test Description</span>
                  <Textarea
                    rows="4"
                    name="test_description"
                    placeholder="Enter test description here."
                    value={testData?.test_description || ""}
                    onChange={handleChange}
                  />
                </Label>
              </div>

              <div className="flex justify-center mt-5 col-span-4">
                <Button type="submit" className="!bg-blue-500 !hover:bg-blue-600 !text-white" disabled={loading}>
                  {loading
                    ? mode === "create"
                      ? "Creating..."
                      : "Updating..."
                    : mode === "create"
                      ? "Create Test"
                      : "Update Test"}
                </Button>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
}

export default BusinessPartnerTestForm;
