// BookingList.jsx

import {
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  FunctionField,
  List,
  ReferenceField,
  SearchInput,
  ShowButton,
  SimpleList,
  TextField,
  TopToolbar,
  usePermissions,
} from "react-admin";
import { useMediaQuery } from "@mui/material";

const AppointmentFilter = [<SearchInput source="booking_name" placeholder="Customer name" alwaysOn />];

const ListActions = () => {
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      <CreateButton />
      {permissions?.role === "Staff" && <ExportButton />}
    </TopToolbar>
  );
};

const BookingList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"), { noSsr: true });

  return (
    <List
      {...props}
      filters={AppointmentFilter}
      sort={{ field: "appt_start_dt", order: "DESC" }}
      actions={<ListActions />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.booking_name}
          secondaryText={(record) => record.service_name}
          tertiaryText={(record) => new Date(record.appt_start_dt).toLocaleDateString()}
          linkType="show"
        />
      ) : (
        <Datagrid>
          <TextField source="id" label="Appointment ID" emptyText="-" />
          <TextField source="service_name" label="Service" emptyText="-" />
          <ReferenceField source="customer_id" target="id" label="Customer" reference="customers">
            <FunctionField
              label="Customer Name"
              render={(record) => `${record.first_name} ${record.last_name}`}
              emptyText="-"
            />
          </ReferenceField>
          <TextField source="appt_location" label="Appointment Location" emptyText="-" />
          <DateField
            source="appt_start_dt"
            label="Appointment Start Time"
            showTime
            options={{ timeZone: "UTC" }}
            emptyText="-"
          />
          <DateField
            source="appt_end_dt"
            label="Appointment End Time"
            options={{ timeZone: "UTC" }}
            showTime
            emptyText="-"
          />
          <TextField source="booking_status" label="Status" emptyText="-" />
          <ShowButton />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default BookingList;
