import { redirect } from "react-router-dom";
import { postRequest } from "./requests";

const login = async ({ email, password }) => {
  try {
    const response = await postRequest("auth/login", { email, password });
    if (response.status === "success") {
      localStorage.setItem("email", email);
      localStorage.setItem("user_metadata", JSON.stringify(response.user));
      localStorage.setItem("permissions", JSON.stringify(response.access));
      return Promise.resolve();
    }
    throw new Error(response.message || "Login failed");
  } catch (error) {
    return Promise.reject(error);
  }
};

const logout = () => {
  localStorage.removeItem("email");
  localStorage.removeItem("user_metadata");
  localStorage.removeItem("permissions");
  localStorage.removeItem("nurse_profile");
  redirect("/login");
  return Promise.resolve();
};

const checkError = ({ status }) => {
  if (status === 401 || status === 403) {
    localStorage.removeItem("email");
    localStorage.removeItem("user_metadata");
    localStorage.removeItem("permissions");
    localStorage.removeItem("nurse_profile");
    return Promise.reject();
  }
  return Promise.resolve();
};

const checkAuth = () => {
  return localStorage.getItem("email") ? Promise.resolve() : Promise.reject();
};

const getPermissions = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  return Promise.resolve(permissions);
};

const authProvider = {
  login,
  logout,
  checkError,
  checkAuth,
  getPermissions,
};

export default authProvider;
