import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { Scheduler } from "smart-webcomponents-react/scheduler";
import { getRequest } from "../../helpers/requests";
import Loading from "../../components/preloader/Loading";
import { convertData } from "./helper";
import { useNavigate } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";
import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import mbxMatrix from "@mapbox/mapbox-sdk/services/matrix";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const mapboxClient = mbxGeocoding({
  accessToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
});

const matrixClient = mbxMatrix({
  accessToken: process.env.REACT_APP_MAPBOX_API_TOKEN,
});

const ClinicianAvailability = () => {
  const navigate = useNavigate();
  const scheduler = useRef();
  const [availability, setAvailability] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [people, setPeople] = useState([]);
  const [defaultPeople, setDefaultPeople] = useState([]);
  const [postcode, setPostcode] = useState("");
  const [filteredClinicians, setFilteredClinicians] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const view = "week";
  const views = [
    "day",
    { type: "week" },
    { type: "month" },
    "agenda",
    {
      label: "4 days",
      value: "workWeek",
      type: "week",
      shortcutKey: "X",
      hideWeekend: false,
      hideNonworkingWeekdays: true,
    },
  ];
  const hideAllDay = true;
  const firstDayOfWeek = 1;
  const disableDateMenu = false;
  const shadeUntilCurrentTime = true;
  const currentTimeIndicator = true;
  const scrollButtonsPosition = "far";

  useEffect(() => {
    const fetchClinicians = async () => {
      try {
        const clinicians = await getRequest("users/clinicians");
        const list = clinicians.clinician;

        const updatedList = await Promise.all(
          list.map(async (person) => {
            const fullAddressParts = [
              person.address_line1,
              person.address_line2,
              person.city,
              person.postcode,
              person.country,
            ].filter(Boolean);
            const fullAddress = fullAddressParts.join(", ");

            if (!fullAddress.trim()) return person;

            if (!person.latitude || !person.longitude) {
              try {
                const response = await mapboxClient
                  .forwardGeocode({
                    query: fullAddress,
                    countries: ["gb"],
                    limit: 1,
                  })
                  .send();

                if (response.body.features.length > 0) {
                  const [lng, lat] = response.body.features[0].geometry.coordinates;
                  return { ...person, latitude: lat, longitude: lng };
                }
              } catch (error) {
                console.error(`Error geocoding address for user_id ${person.user_id}:`, error);
              }
            }
            return person;
          }),
        );

        setPeople(updatedList);
        setDefaultPeople(updatedList);
      } catch (error) {
        console.error("Error fetching clinicians:", error);
      } finally {
        setDataLoaded(true);
      }
    };
    fetchClinicians();
  }, []);

  const handlePostcodeSearch = async (event) => {
    const value = event.target.value;
    setPostcode(value);

    // We'll only do the Matrix lookup if the user typed > 3 characters (your existing logic)
    if (value.length > 3) {
      try {
        // 1) Forward Geocoding for the inputted postcode
        const geocodeResponse = await mapboxClient
          .forwardGeocode({
            query: value,
            countries: ["gb"],
            limit: 1,
          })
          .send();

        // If Mapbox returns at least one feature for the postcode
        if (geocodeResponse.body.features.length > 0) {
          const [originLng, originLat] = geocodeResponse.body.features[0].geometry.coordinates;

          // 2) Find all clinicians who have valid lat/long
          let validIndices = [];
          defaultPeople.forEach((person, index) => {
            if (person.latitude && person.longitude) {
              validIndices.push(index);
            }
          });

          // If no valid coordinates, bail out
          if (validIndices.length === 0) {
            setFilteredClinicians([]);
            return;
          }

          /* 
            3) We'll create a big array to hold computed distances for every
               person, defaulting to Infinity if we never get a real distance.
          */
          let aggregatedDistances = new Array(defaultPeople.length).fill(Infinity);

          /*
            4) Chunk the validIndices so that each chunk has at most 24 destinations
               (since 1 origin + 24 destinations = 25 coordinates max in Matrix API).
          */
          const CHUNK_SIZE = 24;

          for (let start = 0; start < validIndices.length; start += CHUNK_SIZE) {
            const end = Math.min(start + CHUNK_SIZE, validIndices.length);
            const chunk = validIndices.slice(start, end);

            // Build the points array: origin first, then all chunk destinations
            const destinationPoints = chunk.map((idx) => ({
              coordinates: [parseFloat(defaultPeople[idx].longitude), parseFloat(defaultPeople[idx].latitude)],
            }));

            const points = [{ coordinates: [originLng, originLat] }, ...destinationPoints];

            // 5) Call the Matrix API with sources=[0] so we only care about distance from origin
            const matrixResponse = await matrixClient
              .getMatrix({
                points,
                sources: [0], // We'll only compute distances from the single origin
                annotations: ["distance"],
              })
              .send();

            // distances is a 2D array; .distances[0] is the row from the origin
            const chunkDistances = matrixResponse.body.distances[0];

            // 6) Map each chunk distance to the correct clinician in aggregatedDistances
            chunk.forEach((personIndex, i) => {
              const distanceMeters = chunkDistances[i + 1]; // i+1 -> offset for the origin itself
              const distanceMiles = distanceMeters * 0.000621371;
              aggregatedDistances[personIndex] = distanceMiles;
            });
          }

          // 7) Now we have distances for all clinicians in aggregatedDistances
          //    Filter out those more than 62 miles away & sort ascending
          const cliniciansWithDistance = defaultPeople.map((person, index) => ({
            ...person,
            distance: aggregatedDistances[index],
          }));

          const filteredAndSorted = cliniciansWithDistance
            .filter((person) => person.distance <= 62)
            .sort((a, b) => a.distance - b.distance);

          setFilteredClinicians(filteredAndSorted);
        }
      } catch (error) {
        console.error("Error retrieving distance matrix for postcode search:", error);
      }
    } else {
      // If postcode length <= 3, just reset filteredClinicians to empty
      setFilteredClinicians([]);
    }
  };

  const toggleAccordionContent = async (id) => {
    setLoading(true);
    const request = await getRequest(`bookings/availability/5/clinician/${id}`);
    if (request) {
      const availability = convertData(request);
      setAvailability(availability);
      setLoading(false);
    }
  };

  const updateData = (e) => {
    const item = e.detail.item;
    for (let i = 0; i < availability.length; i++) {
      const dataItem = availability[i];
      if (dataItem.label === item.label && dataItem.class === item.class) {
        e.type === "itemRemove" ? availability.splice(i, 1) : availability.splice(i, 1, item);
        return;
      }
    }
  };

  const handleDateChange = (e) => {
    scheduler.current.selectedDates = [e.detail.value];
  };

  const handleNavigate = (event, userId) => {
    event.stopPropagation();
    navigate(`/nurse-profile/${userId}`);
  };

  const buildAddressDisplay = (person) => {
    const fullAddressParts = [
      person.address_line1,
      person.address_line2,
      person.city,
      person.postcode,
      person.country,
    ].filter(Boolean);
    return fullAddressParts.join(", ");
  };

  const handleCreateClinician = () => {
    setOpenAccordion(null);
    navigate("/create-clinician");
  };

  return (
    <div className="m-5">
      <div className="grid grid-cols-2 mb-2">
        <div className="col-start-1 col-end-2 flex justify-start">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField variant="outlined" label="Search postcode" value={postcode} onChange={handlePostcodeSearch} />
          </Box>
        </div>
        <div className="col-end-3 flex justify-end">
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateClinician}
            sx={{ margin: "8px 0 4px" }}
          >
            Create New
          </Button>
        </div>
      </div>

      {dataLoaded ? (
        <>
          {postcode.length > 3 ? (
            filteredClinicians.length > 0 ? (
              filteredClinicians.map((person) => {
                const addressDisplay = buildAddressDisplay(person);
                return (
                  <Accordion
                    key={person.user_id}
                    onChange={(event, isExpanded) => {
                      event.stopPropagation();
                      if (isExpanded) {
                        setOpenAccordion(person.user_id);
                        toggleAccordionContent(person.user_id);
                      } else {
                        setOpenAccordion(null);
                      }
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <IconButton
                            size="small"
                            aria-label="Go to details"
                            onClick={(e) => handleNavigate(e, person.user_id)}
                          >
                            <InfoOutlined />
                          </IconButton>
                          <ExpandMoreIcon />
                        </Box>
                      }
                    >
                      <div className="flex min-w-0 gap-x-4">
                        <div className="min-w-0 flex-auto">
                          <Typography variant="subtitle1" fontWeight="bold">
                            {person.full_name || `${person.first_name || ""} ${person.last_name || ""}`}
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="span"
                              sx={{ marginLeft: "8px" }}
                            >
                              {person.is_nurse ? "Nurse" : "Phlebotomy"}
                            </Typography>
                          </Typography>
                          {/* Internal Rating (Stars) */}
                          {typeof person.internal_rating === "number" && (
                            <Box className="flex items-center mb-1">
                              {[...Array(5)].map((_, index) => {
                                return index < person.internal_rating ? (
                                  <StarIcon key={index} fontSize="small" style={{ color: "#1976d2" }} />
                                ) : (
                                  <StarBorderIcon key={index} fontSize="small" style={{ color: "#1976d2" }} />
                                );
                              })}
                              <Typography variant="body2" color="textSecondary" sx={{ marginLeft: "8px" }}>
                                {person.internal_rating}/5
                              </Typography>
                            </Box>
                          )}
                          <Typography variant="body2" color="textSecondary">
                            {person.username}
                            {person.phone_number && ` | ${person.phone_number}`}
                            {addressDisplay && ` | ${addressDisplay}`}
                            {person.distance !== undefined && person.distance !== Infinity && (
                              <span>{` — ${person.distance.toFixed(1)} miles away`}</span>
                            )}
                          </Typography>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {openAccordion === person.user_id && loading && <Loading />}
                      {openAccordion === person.user_id && !loading && (
                        <div className="flex flex-col">
                          <Scheduler
                            ref={scheduler}
                            id="scheduler"
                            dataSource={availability}
                            view={view}
                            views={views}
                            hideAllDay={hideAllDay}
                            firstDayOfWeek={firstDayOfWeek}
                            disableDateMenu={disableDateMenu}
                            currentTimeIndicator={currentTimeIndicator}
                            scrollButtonsPosition={scrollButtonsPosition}
                            onItemUpdate={updateData}
                            onItemRemove={updateData}
                            onDateChange={handleDateChange}
                            shadeUntilCurrentTime={shadeUntilCurrentTime}
                            disableWindowEditor
                          />
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })
            ) : (
              <Typography variant="body1" sx={{ marginTop: "16px" }}>
                There are no nurses available around the current search location within 62 miles.
              </Typography>
            )
          ) : (
            people.map((person) => {
              const addressDisplay = buildAddressDisplay(person);
              return (
                <Accordion
                  key={person.user_id}
                  onChange={(event, isExpanded) => {
                    event.stopPropagation();
                    if (isExpanded) {
                      setOpenAccordion(person.user_id);
                      toggleAccordionContent(person.user_id);
                    } else {
                      setOpenAccordion(null);
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <IconButton
                          size="small"
                          aria-label="Go to details"
                          onClick={(e) => handleNavigate(e, person.user_id)}
                        >
                          <InfoOutlined />
                        </IconButton>
                        <ExpandMoreIcon />
                      </Box>
                    }
                  >
                    <div className="flex min-w-0 gap-x-4">
                      <div className="min-w-0 flex-auto">
                        <Typography variant="subtitle1" fontWeight="bold">
                          {person.full_name || `${person.first_name || ""} ${person.last_name || ""}`}
                          <Typography variant="body2" color="textSecondary" component="span" sx={{ marginLeft: "8px" }}>
                            {person.is_nurse ? "Nurse" : "Phlebotomy"}
                          </Typography>
                        </Typography>
                        {/* Internal Rating (Stars) */}
                        {typeof person.internal_rating === "number" && (
                          <Box className="flex items-center mb-1">
                            {[...Array(5)].map((_, index) => {
                              return index < person.internal_rating ? (
                                <StarIcon key={index} style={{ fontSize: "14px", color: "#1976d2" }} />
                              ) : (
                                <StarBorderIcon key={index} style={{ fontSize: "14px", color: "#1976d2" }} />
                              );
                            })}
                            <Typography variant="body2" color="textSecondary" sx={{ marginLeft: "8px" }}>
                              {person.internal_rating}/5
                            </Typography>
                          </Box>
                        )}
                        <Typography variant="body2" color="textSecondary">
                          {person.username}
                          {person.phone_number && ` | ${person.phone_number}`}
                          {addressDisplay && ` | ${addressDisplay}`}
                        </Typography>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {openAccordion === person.user_id && loading && <Loading />}
                    {openAccordion === person.user_id && !loading && (
                      <div className="flex flex-col">
                        <Scheduler
                          ref={scheduler}
                          id="scheduler"
                          dataSource={availability}
                          view={view}
                          views={views}
                          hideAllDay={hideAllDay}
                          firstDayOfWeek={firstDayOfWeek}
                          disableDateMenu={disableDateMenu}
                          currentTimeIndicator={currentTimeIndicator}
                          scrollButtonsPosition={scrollButtonsPosition}
                          onItemUpdate={updateData}
                          onItemRemove={updateData}
                          onDateChange={handleDateChange}
                          shadeUntilCurrentTime={shadeUntilCurrentTime}
                          disableWindowEditor
                        />
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ClinicianAvailability;
