import { ListButton, Toolbar } from "react-admin";
import { useNavigate } from "react-router-dom";
import { Button, Input, Label, Select } from "@windmill/react-ui";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../helpers/requests";
import SearchableDropdown from "../searchable-dropdown";
import appointmentMapper from "helpers/bookingMapper";
import { notifyError } from "../../utils/toast";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

const ActionButtons = () => (
  <Toolbar>
    <ListButton label="Bookings" />
  </Toolbar>
);

// ----------------------- START OF SERVICES LIST -----------------------
const servicesList = [
  // --- Services with Lab Codes ---
  {
    service_code: "standardHealthScreening",
    service_name: "Standard Health Screening",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 19800,
  },
  {
    service_code: "heartHealth",
    service_name: "Heart Health",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 17900,
  },
  {
    service_code: "paediatricCardiovascularSurgeryMemorial",
    service_name: "Paediatric Cardiovascular Surgery",
    email_template: "",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "sportHealthPackage",
    service_name: "Sport Health Package",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 29500,
  },
  {
    service_code: "weightManagement",
    service_name: "Understand Your Weight",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 47500,
  },
  {
    service_code: "chronicFatiguePackage",
    service_name: "Chronic Fatigue Package",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 37900,
  },
  {
    service_code: "gutHealth",
    service_name: "Gut Health (Self-collection)",
    email_template: "gutHealth",
    service_type: "Postal",
    service_price: 8900,
  },
  {
    service_code: "Calprotectin",
    service_name: "Calprotectin (Self-collection)",
    email_template: "postalTest",
    service_type: "Postal",
    service_price: 7000,
  },
  {
    service_code: "QFIT",
    service_name: "QFIT (Self-collection)",
    email_template: "postalTest",
    service_type: "Postal",
    service_price: 4900,
  },
  {
    service_code: "ironPanel",
    service_name: "Iron Panel",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 9900,
  },
  {
    service_code: "thyroidScreening",
    service_name: "Thyroid Screening",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 9900,
  },
  {
    service_code: "maleHormoneScreening",
    service_name: "Male Hormone Screening",
    email_template: "hormoneTest",
    service_type: "Clinic",
    service_price: 16500,
  },
  {
    service_code: "femaleHormoneScreening",
    service_name: "Female Hormone Screening",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 16500,
  },
  {
    service_code: "preFertilityTreatment",
    service_name: "Pre-Fertility Treatement Test",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 62500,
  },
  {
    service_code: "travelPreEmploymentTests",
    service_name: "Travel & Pre-Employment Medicals",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 39800,
  },
  {
    service_code: "PSA",
    service_name: "Prostate screening test (PSA)",
    email_template: "PSATest",
    service_type: "Clinic",
    service_price: 7900,
  },
  {
    service_code: "PLAC",
    service_name: "PLAC",
    email_template: "PLACTest",
    service_type: "Clinic",
    service_price: 8900,
  },
  {
    service_code: "Urine",
    service_name: "Urine (Self-Collection)",
    email_template: "urine",
    service_type: "Postal",
    service_price: 5500,
  },
  {
    service_code: "vitaminD",
    service_name: "Vitamin D",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 6500,
  },
  {
    service_code: "HIVSyphilis",
    service_name: "HIV & Syphilis",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 8000,
  },
  {
    service_code: "Syphilis",
    service_name: "Syphilis",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 6500,
  },
  {
    service_code: "HPV",
    service_name: "HPV (Self-collection)",
    email_template: "HPVTest",
    service_type: "Postal",
    service_price: 6500,
  },
  {
    service_code: "chinaboundPCR24hr",
    service_name: "Covid-19 RT-PCR (48hr result)",
    email_template: "covid19",
    service_type: "Clinic",
    service_price: 8900,
  },
  {
    service_code: "cancerMarkers",
    service_name: "Cancer Markers",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 15500,
  },
  {
    service_code: "testProfile1",
    service_name: "IUI Diers Test Profile 1",
    email_template: "generalBloodTest",
    service_type: "In-Clinic, Home Visit",
    service_price: 14800,
  },
  {
    service_code: "testProfile2",
    service_name: "IUI Diers Test Profile 2 (by Post)",
    email_template: "generalBloodTest",
    service_type: "Postal",
    service_price: 8900,
  },
  {
    service_code: "testProfile3",
    service_name: "IUI Diers Test Profile 3 (by Post; AMH)",
    email_template: "generalBloodTest",
    service_type: "Postal",
    service_price: 6600,
  },
  {
    service_code: "testProfile4",
    service_name: "IUI Diers Test Profile 4 (by Post; Progesterone)",
    email_template: "generalBloodTest",
    service_type: "Postal",
    service_price: 5000,
  },
  {
    service_code: "preOpTestTirana",
    service_name: "Pre-operative test",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 32800,
  },
  {
    service_code: "fullPostOpBloodTestBariaticSurgeries",
    service_name: "Full Post-op Follow-up Blood Test (Bariatric Surgery)",
    email_template: "generalBloodTest",
    service_type: "Clinic, Home Visit",
    service_price: 19800,
  },
  {
    service_code: "preOperativeTestBariatricSurgeries",
    service_name: "Pre-operative Test (Bariatric Surgery)",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 17900,
  },
  {
    service_code: "preOperativePlasticSurgery",
    service_name: "Pre-operative Test (Plastic Surgeries)",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 17900,
  },
  {
    service_code: "liverThyroidLipidsCambridgeLab",
    service_name: "Liver, Kidney, Thyroid and Lipids",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 17900,
  },
  {
    service_code: "liverCambridgeLab",
    service_name: "Liver",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 8400,
  },
  {
    service_code: "lipidsCambridgeLab",
    service_name: "Lipids",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 8400,
  },
  // --- Services Without Lab Codes (use "NA") ---
  {
    service_code: "fitTestCambridge",
    service_name: "FIT Test",
    email_template: "postalTest",
    service_type: "Postal, Self-Collection",
    service_price: 3600,
  },
  {
    service_code: "APSTestProfile",
    service_name: "APS Test Profile",
    email_template: "generalBloodTest",
    service_type: "In-Clinic, Home Visit",
    service_price: 18900,
  },
  {
    service_code: "proclarixCambridge",
    service_name: "Proclarix (including PSA)",
    email_template: "PSATest",
    service_type: "Clinic",
    service_price: 22800,
  },
  {
    service_code: "menopauseGMR",
    service_name: "Menopause",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "hormoneHivSyphilisTestsMemorial",
    service_name: "Hormone, HIV & Syphilis tests",
    email_template: "generalBloodTest",
    service_type: "In-Clinic,Home Visit",
    service_price: 18000,
  },
  {
    service_code: "earlyEDTLungCambridge",
    service_name: "EarlyCDT Lung",
    email_template: "postalTest",
    service_type: "Clinic, Postal, Self-Collection",
    service_price: 18000,
  },
  {
    service_code: "allergyTest",
    service_name: "Allergy Test",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "cardiacHealthMemorial",
    service_name: "Cardiac Health",
    email_template: "",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "preOperativeSpineSurgery",
    service_name: "Spine surgery pre-operative test package",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 29900,
  },
  {
    service_code: "amhBloodTest",
    service_name: "AMH Blood Test",
    email_template: "",
    service_type: "In-Clinic, Home Visit",
    service_price: 11000,
  },
  {
    service_code: "IVFtestProfile1",
    service_name: "IVF Diers Test Profile 1",
    email_template: "generalBloodTest",
    service_type: "In-Clinic, Home Visit",
    service_price: 14800,
  },
  {
    service_code: "IVFtestProfile2",
    service_name: "IVF Diers Test Profile 2",
    email_template: "generalBloodTest",
    service_type: "In-Clinic, Home Visit",
    service_price: 17800,
  },
  {
    service_code: "IVFDiersTestProfile3Men",
    service_name: "IVF Diers Test Profile 3 (Men)",
    email_template: "generalBloodTest",
    service_type: "In-Clinic, Home Visit",
    service_price: 16800,
  },
  {
    service_code: "hereditaryGeneticScreeningCambridge",
    service_name: "Hereditary genetic screening",
    email_template: "postalTest",
    service_type: "Clinic, Postal, Self Collection",
    service_price: 59880,
  },
  {
    service_code: "IVFDiersTestProfile4Women",
    service_name: "IVF Diers Test Profile 4 (Women using their own eggs)",
    email_template: "generalBloodTest",
    service_type: "In-Clinic, Home Visit",
    service_price: 21200,
  },
  {
    service_code: "CFMH01",
    service_name: "CFMH Blood Tests",
    email_template: "generalBloodTest",
    service_type: "In-Clinic, Home Visit, Postal",
    service_price: 24500,
  },
  {
    service_code: "restingECG",
    service_name: "Resting ECG",
    email_template: "restingECG",
    service_type: "In-Clinic",
    service_price: 13500,
  },
  {
    service_code: "gpConsultation",
    service_name: "GP Consultation",
    email_template: "virtualGP",
    service_type: "Consultation",
    service_price: 10000,
  },
  {
    service_code: "gpServicesGMR",
    service_name: "GP Services",
    email_template: "",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "wholenessPodCryotherapy",
    service_name: "Cryotherapy",
    email_template: "",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "DEXA",
    service_name: "DEXA",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "CT",
    service_name: "CT",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "MRI",
    service_name: "MRI",
    email_template: "imaging",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "ultrasoundScan",
    service_name: "Ultrasound Scan",
    email_template: "imaging",
    service_type: "In-Clinic",
    service_price: 15000,
  },
  {
    service_code: "xRay",
    service_name: "X-Ray",
    email_template: "imaging",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "wholenessPodLight",
    service_name: "Light Therapy",
    email_template: "",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "wholenessPodHBOT",
    service_name: "Hyperbaric Oxygen Therapy",
    email_template: "",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "lifeStyleAdviceGMR",
    service_name: "Life Style Advice",
    email_template: "",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "oncology",
    service_name: "Oncology",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "endoscopy",
    service_name: "Endoscopy",
    email_template: "generalBloodTest",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "physiotherapyGMR",
    service_name: "Physiotherapy",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "virtualPhysiotherapyAndy",
    service_name: "Virtual Physiotherapy Session",
    email_template: "virtualGP",
    service_type: "Virtual",
    service_price: 5700,
  },
  {
    service_code: "spinalHealthCentreMemorial",
    service_name: "Spinal Health Centre",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "strokeRehabilitationCentre ",
    service_name: "Stroke Rehabilitation Centre",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "NApaediatricCardiovascularSurgeryMemorial",
    service_name: "Paediatric Cardiovascular Surgery",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "occupationalHealthGMR",
    service_name: "Occupational Health",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "Mammogram",
    service_name: "Mammogram",
    email_template: "imaging",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "vaccinationsGMR",
    service_name: "Vaccinations",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "preOperativeOphthalmologySurgery",
    service_name: "Ophthalmology Surgery Pre-operative Test Package",
    email_template: "generalBloodTest",
    service_type: "In-Clinic",
    service_price: 32500,
  },
  {
    service_code: "organTransplantMemorial",
    service_name: "Organ Transplant",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "wholenessPodInfrared",
    service_name: "Far Infrared Sauna",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "bladderEpiCheckCambridge",
    service_name: "Bladder EpiCheck",
    email_template: "postalTest",
    service_type: "Clinic, Postal, Self-Collection",
    service_price: 22800,
  },
  {
    service_code: "privateGP",
    service_name: "Private GP Service",
    email_template: "",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "checkupCentre",
    service_name: "Check-up Centre",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "diersTestProfile1Clinic",
    service_name: "Diers Test Profile 1 (in-clinic OR home visit)",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 14800,
  },
  {
    service_code: "reproductiveMedicineInfertilityIvf",
    service_name: "Reproductive Medicine Infertility IVF",
    email_template: "",
    service_type: "In-Clinic",
    service_price: 0,
  },
  {
    service_code: "preOpTestOrthopaedicBloodTest",
    service_name: "Pre-operative Test (Orthopaedic Blood Test)",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 19800,
  },
  {
    service_code: "preOperativeGynaecologySurgery",
    service_name: "Gynaecology Surgery Pre-operative Test Package",
    email_template: "generalBloodTest",
    service_type: "In-Clinic",
    service_price: 31500,
  },
  {
    service_code: "preOperativeUrologySurgery",
    service_name: "Urology Surgery Pre-operative Test Package",
    email_template: "generalBloodTest",
    service_type: "In-Clinic",
    service_price: 29900,
  },
  {
    service_code: "paediatricsAcibademHospital",
    service_name: "Paediatrics",
    email_template: "generalBloodTest",
    service_type: "Clinic",
    service_price: 0,
  },
  {
    service_code: "preOperativeOtolaryngologySurgery",
    service_name: "Otolaryngology Surgery Pre-operative Test Package",
    email_template: "generalBloodTest",
    service_type: "In-Clinic",
    service_price: 33800,
  },
  {
    service_code: "Phlebotomy",
    service_name: "Phlebotomy Services",
    email_template: "phlebotomy",
    service_type: "In-Clinic",
    service_price: 8000,
  },
];
// ------------------------ END OF SERVICES LIST ------------------------

const BookingCreate = (props) => {
  const navigate = useNavigate();

  const [customers, setCustomers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [services, setServices] = useState(servicesList); // <-- Use static servicesList
  const [clinicians, setClinicians] = useState([]);
  const [customAddressEnabled, setCustomAddressEnabled] = useState(false);
  const [customAddress, setCustomAddress] = useState("");

  const [formData, setFormData] = useState({
    selectedCustomer: null,
    selectedService: null,
    selectedLocation: null,
    selectedClinician: null,
    startTime: "",
    endTime: "",
    bookingStatus: "",
    lab: "TDL", // Default is TDL
  });

  useEffect(() => {
    const fetchLocationList = async () => {
      const response = await getRequest("bookings/location");
      if (response) {
        setLocations([{ id: "custom", city: "Enter a Custom Address" }, ...response]);
      }
    };
    fetchLocationList();
  }, []);

  useEffect(() => {
    const fetchCustomerList = async () => {
      const response = await getRequest("customers/list");
      if (response) {
        setCustomers(response);
      }
    };
    fetchCustomerList();
  }, []);

  useEffect(() => {
    const fetchClinicianList = async () => {
      try {
        const response = await getRequest("users/clinicians");
        if (response) {
          setClinicians(response.clinician);
        }
      } catch (error) {
        notifyError("Failed to fetch clinicians");
      }
    };
    fetchClinicianList();
  }, []);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
    if (field === "selectedLocation" && value && value.id === "custom") {
      setCustomAddressEnabled(true);
      setFormData((prevData) => ({
        ...prevData,
        selectedLocation: { is_custom: true },
      }));
    } else if (field === "selectedLocation") {
      setCustomAddressEnabled(false);
      setCustomAddress("");
    }
  };

  const handleCustomAddressChange = (value) => {
    setCustomAddress(value);
  };

  const handleSubmit = async () => {
    const appointmentRequest = {};
    appointmentRequest["requestBooking"] = appointmentMapper(formData);

    if (customAddressEnabled) {
      appointmentRequest["requestBooking"]["serviceLocation"]["displayName"] = customAddress;
    } else {
      appointmentRequest["locationId"] = formData.selectedLocation.id;
    }

    appointmentRequest["customerId"] = formData.selectedCustomer.customer_id;

    appointmentRequest["lab"] = formData.lab;

    appointmentRequest["requestBooking"]["bookingSource"] = "SIRKKA_Admin_Manual_Individual_Booking";

    const response = await postRequest("bookings/appointments", appointmentRequest);
    if (response) {
      navigate("/bookings/appointments");
    }
  };

  return (
    <>
      <ActionButtons />
      <div className="mt-5 mb-5">
        <Label>Customer*</Label>
        <SearchableDropdown
          options={customers}
          label="name"
          id="id"
          selectedVal={formData.selectedCustomer}
          handleChange={(val) => handleChange("selectedCustomer", val)}
          dropdownType="customer"
        />
      </div>
      <div className="mb-5">
        <Label>Services*</Label>
        <SearchableDropdown
          options={services}
          label="service"
          id="id"
          selectedVal={formData.selectedService}
          handleChange={(val) => handleChange("selectedService", val)}
          dropdownType="services"
        />
      </div>
      <div className="mb-5">
        <Label>Location*</Label>
        <SearchableDropdown
          options={locations}
          label="location"
          id="id"
          selectedVal={formData.selectedLocation}
          handleChange={(val) => handleChange("selectedLocation", val)}
          dropdownType="location"
        />
      </div>

      {/* Conditionally render the custom address input field */}
      {customAddressEnabled && (
        <div className="mb-5">
          <Label>
            <span>Custom Address</span>
            <Input
              type="text"
              value={customAddress}
              onChange={(e) => handleCustomAddressChange(e.target.value)}
              disabled={!customAddressEnabled}
              placeholder="Enter the custom address"
            />
          </Label>
        </div>
      )}

      <div className="mb-5">
        <Label>Clinician (For home visit only)</Label>
        <SearchableDropdown
          options={clinicians}
          label="clinician"
          id="id"
          selectedVal={formData.selectedClinician}
          handleChange={(val) => handleChange("selectedClinician", val)}
          dropdownType="clinician"
        />
      </div>

      {/*LAB field (default = TDL) */}
      <div className="mb-5">
        <Label>Lab*</Label>
        <Select
          className="border h-12 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
          value={formData.lab}
          onChange={(e) => handleChange("lab", e.target.value)}
        >
          <option value="TDL">TDL</option>
          <option value="None">None</option>
        </Select>
      </div>

      <div className="mb-5">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DateTimePicker
            label="Appointment Start Time*"
            slotProps={{ textField: { fullWidth: true } }}
            value={formData.startTime ? dayjs(formData.startTime) : null}
            ampm={false}
            onChange={(newValue) => {
              if (newValue) {
                const formattedDateTime = dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss");
                handleChange("startTime", formattedDateTime);
              }
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="mb-5">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DateTimePicker
            label="Appointment End Time*"
            slotProps={{ textField: { fullWidth: true } }}
            value={formData.endTime ? dayjs(formData.endTime) : null}
            ampm={false}
            onChange={(newValue) => {
              if (newValue) {
                const formattedDateTime = dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss");
                handleChange("endTime", formattedDateTime);
              }
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="mb-5">
        <Label>Booking Status*</Label>
        <Select
          className="border h-12 text-sm focus:outline-none block w-full bg-gray-100 border-transparent focus:bg-white"
          value={formData.bookingStatus}
          onChange={(e) => handleChange("bookingStatus", e.target.value)}
        >
          <option value="status" defaultValue hidden>
            Status
          </option>
          <option value="booked">Booked</option>
          <option value="pending">Pending Payment</option>
          <option value="canceled">Canceled</option>
          <option value="completed">Completed</option>
        </Select>
      </div>
      <div className="mb-5">
        <Button block onClick={handleSubmit}>
          Create appointment
        </Button>
      </div>
    </>
  );
};

export default BookingCreate;
