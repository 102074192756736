import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Card, CardContent, CardHeader } from "@mui/material";
import sidebar from "routes/sidebar";

const Dashboard = () => {
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    setPermissions(permissions);
  }, []);

  return (
    <div className="indexCards" style={{ display: "flex", flexWrap: "wrap", gap: "20px", margin: "20px 8px" }}>
      {Array.isArray(permissions.permissions) && permissions.permissions.length > 0 && (
        <>
          {sidebar
            .filter((route) => {
              const filtered = permissions.permissions.some((permission) => `/${permission.resource}` === route.path);
              return filtered;
            })
            .map((route) =>
              route.routes ? (
                ""
              ) : (
                <Card style={{ flex: "1 1 auto" }}>
                  <NavLink
                    // exact
                    to={route.path}
                    className=""
                    // activeClassName="text-green-500 dark:text-gray-100"
                  >
                    <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <route.icon className="w-5 h-5" aria-hidden="true" />
                    </CardContent>
                    <CardHeader style={{ paddingTop: 0, paddingBottom: 0, textAlign: "center" }} title={route.name} />
                    <CardContent>
                      <p className="text-sm text-gray-500 dark:text-gray-400">{route.description}</p>
                    </CardContent>
                  </NavLink>
                </Card>
              ),
            )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
